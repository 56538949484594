.placeholder {
    background-color: #ebebef;
    color: #bbb;
    text-align: center;
    height: 30px;
    line-height: 30px;
    width: 100%;
  }
  .my-drawer {
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .my-drawer .am-drawer-sidebar {
    background-color: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .my-drawer .am-drawer-sidebar .am-list {
    width: 300px;
    padding: 0;
  }
  .am-drawer-draghandle.am-drawer-draghandle.am-drawer-draghandle{
    width:0;
  }
  .conment_p{
    margin: 0;
    padding: 0;
}
.conment_div{
  word-wrap: break-word;
  word-break: normal;
}
